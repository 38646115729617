import { Component, Vue, Prop } from "vue-property-decorator";
import { Bank } from "@/types/bank";
import { CardType } from "@/types/card";

@Component({
  components: {
    CardInfo: () => import("../../../common/components/CardInfo/CardInfo.vue"),
  },
})
export default class IssueCardLayout extends Vue {
  @Prop({ type: String, required: true })
  private readonly cardHolderName!: string;
  @Prop({ type: Object }) private readonly selectedBank?: Bank;
  @Prop({ type: String, required: true }) private readonly cardType!: CardType;
}
